<template>
  <div class="projSearch">
    <Breadcrumb :title="$route.meta.title" />
    <transition name="moveT">
      <div class="searchNews">
        <div class="itemWrap" style="width:30%">
          <label>日期區間：</label>
          <el-date-picker :editable="false" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="開始日期" end-placeholder="結束日期"
            v-model="rangeDate" ></el-date-picker>
        </div>
        <div class="itemWrap" style="width:30%">
          <label>名單類型：</label>
          <el-select v-model="warningFormType" placeholder="請選擇名單" no-data-text="目前無名單" clearable>
            <el-option v-for="item in typeList" :key="item.value"
              :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="itemWrap" style="width:30%">
          <label>名單狀態：</label>
          <el-select v-model="listQuery.Status" multiple clearable placeholder="請選擇名單" no-data-text="目前無名單">
            <el-option v-for="item in statusList" :key="item.id" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>   
        <div class="itemWrap" style="width:10%">   
          <el-button @click="getListIsUser" type="danger" class="searchLog">
            <font-awesome-icon icon="search" style="margin-right: 5px"/>查詢
          </el-button>
        </div> 
      </div>
    </transition>

    <div class="projSearch__listBox">
      <!-- 名單送審 -->
      <div v-if="$store.state.userInfo.Role == 'operator'" class="projSearch__listBox--button">
        <el-button type="primary" @click="operatorSubmit('waitApproval')">
          <font-awesome-icon icon="user-check" style="margin-right: 5px"/>送審審核
        </el-button>
        <el-button type="primary" @click="operatorSubmit('waitDelete')">
          <font-awesome-icon icon="user-slash" style="margin-right: 5px"/>送審刪除
        </el-button>
      </div>
      <div v-if="$store.state.userInfo.Role == 'manager'" class="projSearch__listBox--button">
        <el-button type="primary" @click="managerSubmit('approvalOrDelete')" icon="el-icon-check">核准</el-button>
        <el-button type="primary" @click="managerSubmit('reject')" icon="el-icon-share">退回</el-button>
      </div>
    
      <!-- table -->
      <el-table ref="multipleTable" border style="width: 95%" empty-text="暫無數據" :row-key="getRowKeys" :data="tableData" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="30" :selectable="selectable" :reserve-selection="true"></el-table-column>
        <el-table-column label="編號" prop="ID" width="50"></el-table-column>
        <el-table-column label="狀態" prop="Status" width="100">
          <template slot-scope="scope">{{statusName(scope.row.Status)}}</template>
        </el-table-column>
        <el-table-column prop="ListType" label="類型" width="100">
          <template slot-scope="scope">
            {{ transListType(scope.row.ListType) }}
          </template>
        </el-table-column>
        <el-table-column label="名單名稱" prop="Name" width="250">
          <template slot-scope="scope">
            <a @click="routerPushForm(scope.row)" class="projSearch__listBox--goDetailPage">
              {{ scope.row.Name }}
            </a>
          </template>
        </el-table-column>
        <el-table-column label="申請人/覆核人" prop="EditUser" width="140"></el-table-column>
        <el-table-column label="建立時間" prop="CreateDt" width="150">
          <template slot-scope="scope">
            <div>{{ filterDate(scope.row.CreateDt) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="編輯時間" prop="EditDt" width="150">
          <template slot-scope="scope">
            <div>{{ filterDate(scope.row.EditDt) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="備註" prop="Memo"></el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <Pagination @currentChange="pageChange" :pageSize="listQuery.Page_Size" :propsCurrentPage="listQuery.Page_Num" :totalLists="totalLists"></Pagination>
    </div>
  </div>
  
</template>

<script>
import Breadcrumb from "../../src/components/Breadcrumb.vue";
import Pagination from "../components/Pagination.vue";
export default {
  components: { Breadcrumb,Pagination },
  data() {
    return {
      tableData: [],
      rangeDate: [],
      selectData: [],
      multipleSelection: [],
      warningFormType: "",
      userRole: this.$store.state.userInfo.Role,
      userID:this.$store.state.userInfo.UserID,
      totalLists: null,
      listQuery: {
        Page_Size: 10, //每頁有幾筆
        Page_Num: 1, //目前頁數
        UserID: this.$store.state.userInfo.UserID,
        CreateCom: [this.$store.state.userInfo.ComName],//使用者的公司
        CreateDept: [],//使用者的部門
        Type: [],//(單選)名單類別people||org
        Status: [],//(複選)名單狀態
        Start_Date: "",//查詢時間
        End_Date: "",//查詢時間
      },
      statusList: [
        { id: 1, label: "編輯中", value: "edit" },
        { id: 2, label: "待覆核", value: "wait_approval" },
        { id: 3, label: "已覆核", value: "approval" },
        { id: 4, label: "已退回", value: "reject" },
        { id: 5, label: "待刪除", value: "wait_delete" },
        { id: 6, label: "已刪除", value: "delete" },
      ],
      typeList: [
        { id: 1, label: "警示人名", value: "people" },
        { id: 2, label: "警示組織", value: "org" },
      ],
    };
  },
  computed: {
    transListType(){
      let text = "無";
      return (En) => {
        if(En==='people'){
          text = '人名'
        }else if(En==='org'){
          text = '組織'
        }
        return text 
      };
    },
    filterDate() {
      return (date) => {
        return this.$dayjs(date).format("YYYY-MM-DD HH:mm");
      };
    },
    statusName(){
      return(status)=>{
        let text = "";
        if(status==='edit'){
          text = "編輯中"
        }else if(status==='approval'){
          text = "已覆核"
        }else if(status==='reject'){
          text = "已退回" 
        }else if(status==='wait_approval'){
          text = "待覆核"
        }else if(status==='delete'){
          text = "已刪除"
        }else if(status==='wait_delete'){
          text = "待刪除"
        }
        return text
      }
    },
    selectable(){
      return(row)=>{
        let status = null
        if(this.userRole==='operator'){
          if(row.Status==='edit' && row.Permission==='True'){
            status = true
          }else{
            status = false
          }
        }else if(this.userRole==='manager'){
          if(row.Status==='wait_approval'||row.Status==='wait_delete'){
            status = true
          }else{
            status = false
          }
        }
        return status
      }
    }
  },
  methods: {
    getRowKeys(row) {
      return row.ListID;
    },
    pageChange(val) {
      this.listQuery.Page_Num = val;
      this.getList();
    },
    async managerSubmit(apiType){
      if(this.multipleSelection.length!==0){
        this.$store.dispatch("loadingHandler", true);
        if(apiType==='approvalOrDelete'){
          let waitApproval = this.multipleSelection.filter(item=>item.Status==='wait_approval')
          let waitApprovalID = waitApproval.map((item)=>(item.ListID))
          let waitDelete = this.multipleSelection.filter(item=>!waitApprovalID.includes(item.ListID))
          let waitDeleteID = waitDelete.map((item)=>(item.ListID))
          if(waitApprovalID.length>0 && waitDeleteID.length===0){
            console.log('waitApprovalID.length>0 && waitDeleteID.length===0');
            let requestData = {
              UserID:this.userID,
              Data:[]
            }
            waitApprovalID.forEach((id)=>{
              requestData.Data.push({
                ListID:id,
              })
            })
            try{
              let approvalResult = await this.doChangeStatus('approval',requestData)
              console.log(approvalResult);
              if(approvalResult==='Success'){
                this.$message({
                  message: '核准覆核成功',
                  type: 'success'
                });
                await this.getList()
              }
            }catch(e){
              this.$message({
                message: '核准覆核失敗',
                type: 'error'
              });
              await this.getList()
            }
          }
          if(waitDeleteID.length>0 && waitApprovalID.length===0){
            console.log('waitDeleteID.length>0 && waitApprovalID.length===0');
            let requestData = {
              UserID:this.userID,
              Data:[]
            }
            waitDeleteID.forEach((id)=>{
              requestData.Data.push({
                ListID:id
              })
            })
            try{
              let deleteApiResult = await this.doChangeStatus('delete',requestData);
              console.log('await的結果',deleteApiResult);
              if(deleteApiResult==='Success'){
                this.$message({
                  message: '核准刪除成功',
                  type: 'success'
                });
                await this.getList()
              }
            }catch(e){
              this.$message({
                message: '核准刪除失敗',
                type: 'error'
              });
              await this.getList()
              
            }
          }
          if(waitDeleteID.length>0 && waitApprovalID.length>0){
            let approvalResult = "";
            let deleteApiResult = "";
            //處理同意覆核
            try{
              let approvalRequest = {
                UserID:this.userID,
                Data:[]
              }
              waitApprovalID.forEach((id)=>{
                approvalRequest.Data.push({
                  ListID:id
                })
              })
              approvalResult = await this.doChangeStatus('approval',approvalRequest)
            }catch(e){
              approvalResult = 'Fail'
            }
            try{
              //處理同意刪除
              let approvalDelRequest = {
                UserID:this.userID,
                Data:[]
              }
              waitDeleteID.forEach((id)=>{
                approvalDelRequest.Data.push({
                  ListID:id
                })
              })
              deleteApiResult = await this.doChangeStatus('delete',approvalDelRequest);
            }catch(e){
              deleteApiResult = 'Fail'
            }
            console.log('approvalResult',approvalResult,'deleteApiResult',deleteApiResult);
            //判斷api成功或失敗給予相對應的alert
            if(approvalResult==='Success' && deleteApiResult==='Success'){
              this.$message({
                message: '核准覆核及核准刪除皆成功',
                type: 'success'
              });
              await this.getList()
            }
            if(approvalResult!=='Success' && deleteApiResult!=='Success'){
              this.$message({
                message: '核准覆核及核准刪除失敗',
                type: 'error'
              });
              await this.getList()
            }
            if(approvalResult==='Success' && deleteApiResult!=='Success'){
              this.$message({
                message: '核准覆核成功,核准刪除失敗',
                type: 'warning'
              });
              await this.getList()
            }
            if(approvalResult!=='Success' && deleteApiResult==='Success'){
              this.$message({
                message: '核准覆核失敗,核准刪除成功',
                type: 'warning'
              });
              await this.getList()
            }
          }
          this.$refs.multipleTable.clearSelection();
        }else if(apiType==='reject'){
          let requestData = {
            UserID:this.userID,
            Data:[]
          }
          this.multipleSelection.forEach((item)=>{
            requestData.Data.push({
              ListID:item.ListID
            })
          })
          this.$api.list.reject(requestData).then((res)=>{
            if(res==='Success'){
              this.$message({
                message: '退回成功',
                type: 'success'
              });
            }else{
              this.$message({
                message: '退回失敗',
                type: 'error'
              });
            }
            this.multipleSelection.splice(0,this.multipleSelection.length)
            this.getList()
          })
        }
      }else{
        this.$message({
          message: '請選擇要審核的項目',
          type: 'warning'
        });
      }
      
    },
    doChangeStatus(apiType,data){
      return new Promise((resolve,reject) => {
        data.IsUser = true;
        this.$api.list[apiType](data)
        .then((res) => {
          console.log(res);
          resolve(res)
          // throw new Error("efwefew") // 用throw語句
        })
        .catch((err)=>{
          reject(apiType)
        })
      });
    },
    operatorSubmit(apiType){
      
      if(this.multipleSelection.length>0){
        console.log(this.multipleSelection);
        this.$store.dispatch("loadingHandler", true);
        let requestData ={
          UserID:this.userID,
          IsUser:true,
          Data:[]
        }
        this.multipleSelection.forEach((item)=>{
          requestData.Data.push({
            ListID:item.ListID,
            Memo:item.Memo
          })
        })
        
        console.log(requestData.Data);
        if( requestData.Memo==undefined)
        {
          requestData.Memo="";
        }
        console.log(requestData);
        
        this.$api.list[apiType](requestData).then((res)=>{
          if(res==='Success'){
            this.$message({
              message: '送審成功',
              type: 'success'
            });
          }else{
            this.$message({
              message: '送審失敗',
              type: 'warning'
            });
          }
          this.$refs.multipleTable.clearSelection();
          // this.multipleSelection.splice(0,this.multipleSelection.length)
          this.getList()
        })
      }else{
        this.$message({
          message: '請選擇要審核的項目',
          type: 'warning'
        });
      }
    },
    handleSelectionChange(selection){
      console.log(selection);
      this.multipleSelection = selection
    },
    deleteNullValue(){
      //名單類型
      if(this.warningFormType){
        this.listQuery.Type = [];
        this.listQuery.Type.push(this.warningFormType);
      }else{
        this.listQuery.Type = [];
      }
      //日期
      if(!!this.rangeDate){
        if(this.rangeDate.length > 0){
          this.listQuery.Start_Date = this.rangeDate[0]
          this.listQuery.End_Date = this.rangeDate[1]
        }
      }else{
        this.listQuery.Start_Date = "";
        this.listQuery.End_Date = "";
      }
      //value為空時,刪除key
      let keys = Object.keys(this.listQuery)
      keys.forEach((key)=>{
        if(Array.isArray(this.listQuery[key]) && this.listQuery[key].length===0){
          delete this.listQuery[key]
        }
        if(!this.listQuery[key]){
          delete this.listQuery[key]
        }
      })
    },
    getList() {
      return new Promise((resolve) => {
        this.$store.dispatch("loadingHandler", true);
        this.deleteNullValue()
        this.$api.list.searchList(this.listQuery).then((res) => {
          this.tableData = res.data;
          this.totalLists = res.total;
          this.$store.dispatch("loadingHandler", false);
          resolve()
        });
      });
    },
    routerPushForm(item) {
      this.$router.push({
        name: "warningListAdd",
        query: {
          caseStatus:item.Status,
          permission:item.Permission,
          warningFormType:item.ListType,
          listID:item.ListID,
        }
      });
    },
    getListIsUser() {
      this.$store.dispatch("loadingHandler", true);
      this.listQuery.Page_Num = 1
      this.deleteNullValue()
      let temp = JSON.parse(JSON.stringify(this.listQuery))
      temp.IsUser = true;
      this.$api.list.searchList(temp).then((res) => {
        this.tableData = res.data;
        this.totalLists = res.total;
        this.$store.dispatch("loadingHandler", false);
      });
    }
  },
  mounted() {
    if(JSON.stringify(this.$route.query)!=='{}'){
      let {caseStatus , startDate , endDate} = {...this.$route.query}
      this.rangeDate = [startDate,endDate]
      this.listQuery.Status.push(caseStatus)
      this.getListIsUser()
    }else{
      this.getList(); 
    }
  },
};
</script>

<style lang="scss">
.projSearch {
  padding: 0px 20px;
  min-height: 100vh;
  position: relative;
  background: linear-gradient(-135deg, #00abb9 20%, #fff 50%, transparent 50%)
    center center / 100% 100%;

  &__setting {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #191970;

    i {
      font-size: 20px;
      padding: 0 16px;
      transition: 0.4s;
      cursor: pointer;

      &.goRound {
        transform: rotate(180deg);
      }

      &:hover {
        color: #00abb9;
      }
    }
  }
  .searchNews{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .itemWrap{
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      margin-right:20px;
      label{
        width: 100px;
        display: block;
        text-align: right;
      }
      &:last-child{
        display: flex;
        align-items: center;
        .el-input{
          width: 300px;
        }
      }
    }
  }
  .searchBox {
    box-sizing: border-box;
    border-bottom: 0px solid #191972;
    &--dateAndFunc {
      padding: 20px 20px 0 20px;
      cursor: pointer;
      .el-select {
        width: 30%;
      }
      .el-range-editor {
        width: 30%;
      }
      .searchLog {
        margin-left: 16px;
      }
      label {
        font-weight: 300;
        color: #2a2a2a;
        letter-spacing: 2px;
        font-size: 1.1rem;
      }
    }
  }

  &__listBox {
    // width: 90%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .el-table {
      width: 100% !important;
    }

    &--button {
      padding: 10px 0;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &--goDetailPage {
      color: #0645ad;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
